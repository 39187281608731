
import {mapGetters, mapMutations, mapActions} from 'vuex';
import GateNotificationTooltip from 'razlet-sdk/lib/components/gate/notification/tooltip';
import { USER, AGENT, CASHIER, AIRPORT_CASHIER, CORPORATE } from 'razlet-sdk/lib/constants/roles';
import ReturnModal from 'razlet-sdk/lib/components/avia/return/return-modal';
import {IS_LOGGED, BALANCE} from 'razlet-sdk/lib/store/auth/getters.type';
import {lockScroll, unlockScroll} from 'razlet-sdk/lib/utils/lock-scroll';
import AuthModal from 'razlet-sdk/lib/components/auth/auth-modal';
import BalanceCard from 'razlet-sdk/lib/components/account/balance-card';
import ContractsModal from 'razlet-sdk/lib/components/auth/contracts-modal';

export default {
  name: 'mobile-header',
  components: {
    BalanceCard,  
    ReturnModal,
    AuthModal,
    ContractsModal,
    GateNotificationTooltip,
  },
  data() {
    return {
      isShowedMenu: false,
      isShowedLogin: false,
      isReturnWindowOpened: false,
      typeReturn: null,
      isSwitchContractModal: false,
    };
  },
  computed: {
    ...mapGetters('auth', [IS_LOGGED, BALANCE]),
    userName() {
      const {user} = this.$store.getters;
      if (typeof user === 'undefined' || user.token === null) {
        return '';
      }
      return `${user.model.surname} ${user.model.name}`;
    },
    filteredMenu() {
      if (!this.isUser || !this.user.model.roles) return this.menu.filter(item => !item.access.length);
      return this.menu.filter(item => !item.access.length || item.access.some(v => this.user.model.roles.includes(v)));
    },
    locales() {
      return this.$i18n.availableLocales;
    },
    menu() {
      return [
        { 
          label: this.$t('menu.home'),
          icon: 'rz-icon-flight-start',
          route: 'index',
          access: [],
        },
        { 
          label: this.$t('menu.upsale'),
          icon: 'rz-icon-hand-luggage',
          route: 'account-upsale',
          access: [AIRPORT_CASHIER],
        },
        { 
          label: this.$t('menu.orders'),
          icon: 'rz-icon-datalist',
          route: 'account-orders',
          access: [USER],
        },
        { 
          label: this.$t('menu.operations'),
          icon: 'rz-icon-clock-arrow',
          route: 'account-operations',
          access: [USER],
        },
        {
          label: this.$t('menu.requests'),
          icon: 'rz-icon-assistant',
          route: 'account-requests',
          access: [CORPORATE, AGENT, CASHIER],
        },
        {
          label: this.$t('menu.tickets'),
          icon: 'rz-icon-avia-ticket',
          route: 'account-tickets',
          access: [USER],
        },
        { 
          label: this.$t('menu.passengers'),
          icon: 'rz-icon-adult',
          route: 'account-passengers',
          access: [USER],
        },
        { 
          label: this.$t('menu.old'),
          icon: 'rz-icon-info',
          route: 'https://old.razlet.ru',
          access: [AGENT, AIRPORT_CASHIER, CASHIER, CORPORATE],
        },
      ];
    },
  },
  watch: {
    '$locale': {
      handler(value) {
        this.$cookiz.set('defaultLanguage', value, {path: '/', maxAge: 60 * 60 * 24 * 365});
        this.initSuluSender({requests: ['getMenu']});
        this.$bus.$emit('locale-changed');
      },
    },
    currentTheme: {
      handler (value) {
        this.$cookiz.set('currentTheme', value, { path: '/', maxAge: 60 * 60 * 24 * 365 });
        if (process.client) this.changeColorScheme();
      },
    },
    '$route': {
      handler() {
        this.isShowedMenu = false;
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(['setTheme']),
    ...mapActions('sulu', ['initSuluSender']),
    showMenu() {
      lockScroll();
      this.isShowedMenu = true;
    },
    closeMenu() {
      unlockScroll();
      this.isShowedMenu = false;
    },
    showLoginMenu() {
      this.isShowedLogin = true;
      this.closeMenu();
    },
    profileAction() {
      if (this.showLogin) {
        if (this.isUser) this.goTo('account-profile');
        else this.showLoginMenu();
      } else {
        window.location.href = 'https://agent.razlet.kg';
      }
    },
    returnTicket() {
      if (this.isAgent) {
        this.$router.push({ name: 'account-requests-create', query: { template: 'empty', types: 'tickets' } });
      } else {
        this.typeReturn = 'exchange';
        this.isReturnWindowOpened = true;
      }
      this.closeMenu();
    },
    findOrder() {
      this.typeReturn = 'search';
      this.closeMenu();
      this.isReturnWindowOpened = true;
    },
    goTo(route) {
      this.closeMenu();
      if (route.startsWith('https')) window.open(route, '_blank');
      else this.$router.push({name: route});
    },
    logout() {
      this.$bus.$emit('logout');
      this.closeMenu();
    },
  },
};
